import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PageNotFound from "./pages/pageNotFound/PageNotFound";
import ErrorBoundary from "./ErrorBoundary";
import '../src/styles/ats.css';
import 'react-phone-input-2/lib/style.css';
import Spin from "antd/es/spin";

const CompanyInfo = lazy(() => import("./pages/careerpage/CompanyInfo"));
const JobDescription = lazy(() => import("./pages/careerpage/JobDescription"));
const FormPreview = lazy(() => import("./pages/formBuilder/FormPreview"));

const Ats = () => {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Routes>
          <Route 
            path=":company_name" 
            element={
              <Suspense fallback={<div className="w-full h-full flex items-center justify-center"><Spin size="large" /></div>}>
                <CompanyInfo />
              </Suspense>
            } 
          />
          <Route 
            path=":company_name/:short_link_id" 
            element={
              <Suspense fallback={<div className="w-full h-full flex items-center justify-center"><Spin size="large" /></div>}>
                <JobDescription />
              </Suspense>
            } 
          />
          <Route 
            path=":company_name/apply/:form_id" 
            element={
              <Suspense fallback={<div className="w-full h-full flex items-center justify-center"><Spin size="large" /></div>}>
                <FormPreview />
              </Suspense>
            } 
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default Ats;
